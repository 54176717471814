import React from 'react';

function About() {
	return (
		<section className="container my-5">
      		<h2>买卖优选</h2>
      		<p>买卖优选：是一款帮助抖音大人更高效的找到匹配的商品，通过创作参考，素材供给，一键挂车及业绩统计功能提升达人橱窗运营效率，提升达人抖音电商变现效率的抖音电商效率工具。
</p>
    	</section>	
	);
}


export default About;

