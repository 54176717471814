import React from 'react';

function Header() {
	return (
		
		<header className="bg-light p-5 text-center zoomBreathingBanner">
			<h1>买卖优选</h1>
      			<img src="large.png" alt="Butterfly Banner" className="img-fluid mt-3 "/>
      			<p>让我们一起创造奇迹</p>
		</header>
	);
}

export default Header;
