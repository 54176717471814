import React from 'react';

function Footer() {
  const startYear = 2023;
  const currentYear = new Date().getFullYear();
  const yearText = startYear === currentYear ? `${startYear}` : `${startYear}-${currentYear}`;

  return (
    <footer className="bg-dark text-light text-center p-3">
      <p>Copyright © {yearText} 广州奇蝶信息技术有限公司 粤ICP备2023146745号-1
</p>
    </footer>
  );
}

export default Footer;