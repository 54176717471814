import React, { useState, FormEvent } from 'react'

function Services() {
    const [name, setName] = useState('')
    const [cellphone, setCellphone] = useState('')
    const [message, setMessage] = useState('')

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        console.log(1111111)
        event.preventDefault();

        const formData = {
            Context: {
                argv: {
                    name: name, 
                    phone: cellphone, 
                    msg: message
                }
            }
        };

        try {
            
            const response = await fetch('/sendmsg/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log("Form submitted successfully");
                // Handle success
                alert('你的信息已成功发送。')
                setName('')
                setCellphone('')
                setMessage('')
            } else {
                console.log("Form submission failed");
                // Handle error
            }
        } catch (error) {
            console.error("Submission error", error);
            // Handle error
        }
    };

	return (
		<>
    <section className="container my-5">
      <h2>Our Services</h2>
      <div className="row">
        <div className="col-md-4">
          <h3>技术服务</h3>
          <p>我们提供专门针对电商领域的技术服务，包括电商平台的技术维护、性能优化、安全监控和数据分析。我们的专家团队能够迅速响应并解决各类技术问题，助力客户的电商业务发展。</p>
        </div>
        <div className="col-md-4">
          <h3>技术开发</h3>
          <p>    我们专注于开发创新的电商解决方案，包括定制化的电商平台、社交媒体集成工具和优化的移动购物体验。我们的目标是通过先进的技术手段，帮助客户抓住电商领域的市场机遇。</p>
        </div>
        <div className="col-md-4">
          <h3>技术咨询</h3>
          <p>   作为电商领域的技术咨询专家，我们为客户提供全面的战略规划和市场分析服务。我们专注于帮助客户在社交平台上实现有效的带货策略，包括内容营销、影响力营销和数据驱动的销售策略。</p>
        </div>
      </div>
    </section>
    <section className="bg-light p-5 text-center">
      <h2>What Our Clients Say</h2>
      <p>"Can't wait to use the tools."</p>
      <p> - 某客户</p>
    </section>

    
    <section className="container my-5">
      <h2>联系我们</h2>
      <form  onSubmit={handleSubmit}>
        <input type="text" className="form-control mb-2" placeholder="您的名字" value={name} onChange={(e) => setName(e.target.value)} />
        <input type="text" className="form-control mb-2" placeholder="您的电话" value={cellphone} onChange={(e) => setCellphone(e.target.value)} />
        <textarea className="form-control mb-2" placeholder="您的留言" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
        <button type="submit" className="btn btn-primary">Send</button>
      </form>
    </section>
    	</>
	);
}

export default Services;
